@import "fa/fontawesome.scss";
@import "fa/solid.scss";
@import "fa/brands.scss";
@import "fa/light.scss";
@import "fa/regular.scss";
@import "fa/sharp-solid.scss";
@import "fa/duotone.scss";
@import "fa/thin.scss";

@import "base";
@import "code";

html {
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
    display:flex;
    flex-direction: column;

    header {

        .popup-menu-background {
            display: none;
            visibility: hidden;
            opacity: 0;
            transition: opacity .3s, visibility .3s;
            will-change: opacity, visibility;
            position: absolute;
            background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0) 100%) ;
            height: 100vh;
            width: 100vw;
            left: 0px;
            top: -14px; //to accomodate 14px padding on header
            z-index: 100;

            @media (max-width: 1100px) {
                display: block;
            }

            &.open {
                visibility: visible;
                opacity: 1;

            }


            .popup-menu {
                background: $off-white;
                position: relative;
                margin: 0;
                border-radius: 10px;
                -webkit-box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.25);
                -moz-box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.25);
                box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.25);
                display: flex;
                color: #222222;
                font-size: 18px;
                height: 760px;
                max-width: 400px;
                margin: 15px;
                flex-direction: column;
                justify-content: flex-start;
                padding: 40px 40px 60px;
                overflow: hidden;

                @media (min-width: 430px) {
                    margin-left: auto;
                    margin-right: 15px;
                }

                a#close-popup-menu {
                    right: 40px;
                    position: absolute;
                }

                a#menu-back {
                    position: absolute;
                    display: none;

                    >i {
                        margin-right: 8px;
                    }
                }

                span#menu-logo {
                    // left: -24px;
                    padding-bottom: 30px;
                }

                li { 
                    // margin: 0 10px;
                    padding: 20px 0;

                    &:not(:last-child) {
                        border-bottom: 1px solid #D7D7D7;
                    } 
                }

                a { color: #222222; }



                ul {
                    padding-left: 0;
                }

                #menu-level-one {
                    position: relative;
                    transform: translate(0px);
                    transition: transform 0.3s;


                    li>a {
                        display: flex;
                        justify-content: space-between;

                        i.fa-angle-right {
                            align-self: center;
                        }
                    }
                }

                .menu-level-two {
                    visibility: hidden;
                    position: absolute;
                    top: 96px;
                    transition: transform 0.3s;
                    transform: translate(400px);
                    width: calc(100% - 100px);

                    ul {
                        padding-left: 0;

                        &:first-of-type {
                            border-bottom: 1px solid #D7D7D7;
                        }
                    }

                    li {
                        border-bottom: none;
                        padding: 8px 0;
        
                        a {
                            display: grid;
                            grid-template-columns: 1fr 5fr;
                            gap: 0.4em;
            
                            &:hover {
                                i {
                                    color: #0D7BAB; 
                                }
                            }
            
                            span {
                                color: #222;
                            }
                    
                            i {
                                align-self: center;
                                justify-self: center;
                                color: #1bb0ee;
                            }
                        }
                    }
                }

                .cta-mobile {
                    display: flex; 
                    justify-content: center;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding: 20px 0px;
                    margin-left: -40px;
                    border-radius: 0px 0px 10px 10px;
                    background-color: $primary; 
                    
                    >a {
                        color: $off-white;
                        font-size: 20px;

                        svg {
                            stroke: $off-white
                        } 
                    }
                }
            }
        }

    }

    main {
        position: relative;
        flex-grow: 1;
        margin-top: -110px;
    }

    footer {

    }
}

.split-header {
    display: flex;
    padding-bottom: 110px;

    .header-title {
        flex: 1 1 auto;
        width: 75%;
        max-width: 890px;
        margin: auto;
        padding: 0 55px;
        @media  (max-width: 900px) {
            padding: 0 20px;
            margin-bottom: -50px;
        }
    }
    .header-aside {
        @media  (max-width: 1276px) {
            display: none;
        }

        flex: 0 1 auto;
        width: 25%;
        min-width: 260px;
        background: $primary;
        margin-bottom: -110px;
        margin-top: 50px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 30px;
        padding-bottom: 80px;

        h3,h4 {
            margin-top: 10px;
        }

        a {
            svg { stroke: $primary; }
        }
    }
}

.live-event-banner {
    width: 100%;
    padding: 8px 16px;
    font-size: 14px;
    background-color: black;
    color: white;
    text-align: center;

    &>a {
        &>img {
            display: inline-block;
            position: relative;
            top: 0.2em;
            height: 1.2em;
            width: 1.2em;
            margin-left: 2px;
            margin-right: 3px;
        }
        text-decoration: none;
    }

}

.wrapper-medium>.header-title {
    margin-bottom: 130px;
}

.header-title {
    padding-top: 40px;
    h1 { margin-top: 10px; }
    position: relative;

    &.center {
        text-align: center;
    }

    .breadcrumb {
        font-weight: bold;
        a { 
            color: white; 
            text-decoration: none;

            &::after {
                content: '>';
                margin: 0 0.5em;
            }
        }

        &.tutorials a {
            color: $primary;
        }

        &.cookbook a {
            color: $tertiary;
        }

        &.examples a {
            color: $secondary;
        }

    }

    div.author {
        color: #999;
        margin-top: -20px;
        font-size: 110%;

        span.name {
            color: $primary;
            font-weight: bold;
        }

        img.author {
            width: 70px;
            border-radius: 35px;
            position: relative;
            vertical-align: middle;
            margin-right: 15px;
            filter: grayscale(100%);

            &:not(:first-child) {
                margin-left: -30px;
            }
        }
    }
    div.human-date {
        margin-top: -30px;
        margin-bottom: 30px;
        color: #999;
    }
}

footer {
    background-color: #1bb0ee;
    color: white;
    min-height: 420px;

    .social-media-container {
        margin-top: 20px;
        i {
            font-size: 24px;
            margin-right: 20px;
        }
    }

    @media(max-width: 700px) {
        .footer-logo-col {

            .logo, .social-media-container {
                display: none;
                
            }
            width: 30px;
            flex-grow: 0;
        }

    }

    @media(max-width: 800px) {
        .social-media-container i {
            font-size: 16px;
        }
    }

    h4 { 
        color: white; 
        font-size: 120%;
    }

    a, a:visited, a:active {
        color: white;
        text-decoration: none;
    }
    ul {
        margin-top: 1em;
        li {
            padding-left:0;
            &:before {
                content: none;
            }
        }
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    >div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        >div {
            flex: 1 0 auto;
            width: 0;

            .logo {
                margin-top: -0.4em;
            }
        }


        &.footer {
            >div:first-child {
                flex: 2 0 auto;
            }
            color: #8de2fe;
            .links {
                text-align: right;
                a { padding: 0 10px; color: #8de2fe;}
            }
            margin-bottom: 1em;
        }

    }

}

.video-intro {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        width: 100%;
        pointer-events: none;
        opacity: 0;
        transition: opacity .5s;

        @media(max-width: 1020px) {
            br { display: none; }
        }

        .small-version {
            display: none;
        }

        @media(max-width: 860px) {
            .small-version {
                display: block;
            }
            .large-version {
                display: none;
            }
        }

        @media(max-width: 660px) {
            .button.disposable {
                display: none;
            }
        }

    }
}

.thumbnail-card {
    margin-bottom: 3em;

    .card {
        overflow: hidden;
        display: flex;

        >.thumbnail {
            &:first-child { padding-top: 50%; display: none; }
            &:last-child { padding-top: 25%; width: 50%; }

            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            background-color: #ddd;
        }
        text-decoration: none;
        color: inherit;

        position: relative;
        width: 100%;
        margin: 0;
        padding: 0;

        .content {
            width: 50%;
            border-right: 1px solid #ccc;
            background-color: #fafafa;
            padding: 2em;
            .title {
                padding-bottom: 0.5em;
            }

            .button {
                float: right;
                svg {stroke: white;}
            }     
        }
    }
    @media (max-width:968px) {
        .card {
            flex-direction: column;
            >.thumbnail:first-child {
                display: block;
            }
            >.thumbnail:last-child {
                display: none;
            }
            .content { width: 100%; border-right: none; }
        }
    }

}

.blog-page {
    padding-bottom: 10em;

    .wrapper-medium {
        padding: 20px;
    }

    h2 {
        padding-left: 5px;
    }

    .blog-hero {
        margin-bottom: 3em;

        .card {
            >.thumbnail:last-child {
                width: 60%;
            }

            .content {
                width: 40%;
                @media (max-width:968px) { width: 100%; }
            }
        }
    }

}

/* Was inside .blog-page; now shared with the podcast index */
.blog-archive {
    display:flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    position: relative;

    .card {
        @media(min-width: 768px) {
            width: calc(50% - 2em);
            min-width: 300px;
        }
        @media(min-width: 1024px) {
            width: calc(33% - 2em);
        }

        overflow: hidden;
        >.thumbnail {
            padding-top: 50%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            border-bottom: 1px solid #ccc;
            background-color: #ddd;
        }
        text-decoration: none;
        color: inherit;

        position: relative;
        margin: 0;
        margin-bottom: 4em;
        padding: 0;

        &:hover {
            box-shadow: 0px 0px 64px 0px rgba(27,27,27,0.25);
        }

        .content {
            padding: 2em;
            .title {
                padding-bottom: 0.5em;
            }

            div.summary {

            }

            .human-date {
                color: #999;
            }
        }
    }

    .end-justify {
        width: calc(33% - 2em);
    }
}

.blog-archive.dashboard-showcase {
    .card {
        @media(min-width: 768px) {
            width: calc(100% - 2em);
            min-width: 300px;
        }
        @media(min-width: 1024px) {
            width: calc(48% - 2em);
        }
    }

}


.blog-page-content {
    display: flex;

    .blog-single, .tutorial-modal-content {
        flex: 1 1 auto;
        width: 75%;
        //margin-left: -50px;
    }

    .blog-aside {

        @media  (max-width: 1276px) {
            display: none;
        }

        flex: 0 1 auto;
        min-width: 260px;
        width: 25%;
        padding-top: 20px;
    }
}

.pricing {

    p {
        margin: auto;
    }

    figcaption > p {
        margin: 0.5em auto;
    }

    .anvil-x-callout {
        background-color: #1bb0ee;
        color: white;
        padding: 20px;
        margin: auto;
        border-radius: 10px;
        box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
        flex-grow: 1;
        max-width: 88.5rem;
        text-align: center;
        font-weight: 600;
        text-decoration: none;

        svg {
            stroke: white;
            width: 1.2em;
            height: 1.2em;
            vertical-align: middle;
        }

    }

    .plans-container {
        display: flex;
        row-gap: 30px;
        column-gap: 40px;
        justify-items: center;
        flex-wrap: wrap;
        max-width: 90rem;
        margin: auto;
    }

    .plan-card {
        display: grid;
        grid-template-rows: 1fr 1fr 7fr 1fr 2fr;
        position: relative;
        width: 260px;
        height: 390px;
        box-shadow: 2px 4px 6px 1px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        margin: auto;
        padding: 1.8em 1.8em;
        font-size: 13px;
        background-color: #F7FCFE;
        color: #222;

        .price-header {
            font-size: 18px;
            font-weight: 600;
            margin: 0px;
            margin-bottom: -0.5em;
        }

        .subtitle {
            font-weight: 500;
            font-style: italic;
            font-size: 14px;
            margin: 0px;
            padding: 0;
            float: left;
            color: #666;
        }
        .get-quote {
            position: relative;
            text-decoration: none;
            font-size: 30px;
            color: #1bb0ee;
        }

        .price, .currency, .frequency {
            display: none;

            &.visible {
                display: inline-block;
            }
        }

        .price {
            position: relative;
            font-size: 30px;
            color: #222;


            &.currency-usd .price-figure::before { content: '$'; }
            &.currency-gbp .price-figure::before { content: '£'; }
            &.currency-eur .price-figure::before { content: '€'; }

            &.frequency-annual .price-figure::after { content: '/mo*'; font-size: 13px; color: #999; }
            &.frequency-monthly .price-figure::after { content: '/mo'; font-size: 13px; color: #999; }
            &.frequency-monthly.with-conditions .price-figure::after { content: '/mo*'; font-size: 13px; color: #999; }
            &.zero .price-figure::after { content: '/mo' !important; font-size: 13px; color: #999; }
        }

        .card-features {
            grid-row:  3;
            padding-top: 0.5em;
        }

        .key-points {
            list-style: none;
            padding: 0px;
        }

        .key-points li {
            margin: 0.25rem;
            padding-left: 0px;
            display: flex;
            align-items: center;

            &::before {
                display: none;
            }

            i.icon-check {
                font-size: 1.55em;
                margin-right: .3em;
                
            }

        }

        // .key-points li::before {
        //     font-family: var(--fa-font-solid);
        //     content: "\f058";
        //     font-size: 10px;
        //     background: #1bb0ee;
        //     width: 2rem;
        //     height: 2rem;
        //     border-radius: 50%;
        //     color: white;
        //     text-align: center;
        //     margin-right: 0.5rem;
        //     top: 0.4em;
        // }

        .extra-info {
            font-size: 10px;
            text-align: center;
            margin: auto;
            color: #999;
            max-width: 200px;
            grid-row: 4;
            padding-bottom: 10px;
        }

        .plan-button {
            cursor: pointer;
            background: #1bb0ee;
            border-radius: 9px;
            text-decoration: none;
            color: white;
            margin: auto;
            text-align: center;
            width: 200px;
            font-weight: 600;
            font-size: 18px;
            padding: 0.5em;
            grid-row: 5;

            &:hover {
                background-color: #109BD6;
                transition: background-color .2s;
            }
        }
    }

    .help-text {
        display: block;
        padding: 1.5em 0;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: #222;

        .contact-sales {
            text-decoration: none;
            color: #1bb0ee;

            &:hover {
                color: #109BD6;
                transition: color .2s;
            }
        }
    }

    .compare-features {
        position: relative;
        max-width: 320px;
        display: block;
        justify-content: center;
        cursor: pointer;
        vertical-align: middle;
        border-radius: 74px;
        background: #222;
        text-decoration: none;
        color: white;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
        margin: auto;

        font-weight: 600;
        padding: 0.5em 1em;

        &:hover {
            box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
        }

        &:focus {
            box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);
        }
    }

    h2 {
        padding: 1rem;
        font-size: 48px;

        &#support {
            font-size: 30px;
            text-align: center;
        }

        &#education {
            font-size: 30px;
            text-align: center;
        }
    }

    .options {
        display: flex;
        font-weight: bold;
        max-width: 85rem;
        margin: auto;
        padding-top: 2em;
        padding-bottom: 1em;
        a {
            color: rgb(84,84,84); 
            text-decoration: none; 
            padding: 0 5px;

            &:hover {
                color: rgb(180,180,180);
            }
            &.active {
                color: white;
                &:hover {
                    color: white;
                }
            }
        }
        >div {
            flex: 1 0 0;
            width: 0;
            &:last-child {
                text-align: right;
            }
        }
    }

    .options-light {
        display: flex;
        font-weight: bold;
        max-width: 85rem;
        margin: auto;
        padding-top: 2em;
        padding-bottom: 1em;
        a {
            color: #999 ; 
            text-decoration: none; 
            padding: 0 5px;

            &:hover {
                color: #bababa;
            }
            &.active {
                color: #555;
                &:hover {
                    color: #555;
                }
            }
        }
        >div {
            flex: 1 0 0;
            width: 0;
            &:last-child {
                text-align: right;
            }
        }
    }

    .options-dark {
        display: flex;
        font-weight: bold;
        max-width: 85rem;
        margin: auto;
        padding-top: 2em;
        padding-bottom: 1em;
        a {
            color: rgb(84,84,84); 
            text-decoration: none; 
            padding: 0 5px;

            &:hover {
                color: rgb(180,180,180);
            }
            &.active {
                color: white;
                &:hover {
                    color: white;
                }
            }
        }
        >div {
            flex: 1 0 0;
            width: 0;
            &:last-child {
                text-align: right;
            }
        }
    }

    .plan-callout-row {

        display: flex;
        position:absolute;
        left:0;
        right:0;
        z-index: 10;
        padding: 0 32px;

        >div {
            flex: 1 0 0;
            text-align:center;
            .plan-callout-div {
                display:inline-block;
                color: white;
                font-weight: bold;
                padding: 0 1em;
                background: rgb(255,101,65);
            }
        }
    }

    .card {
        background-color: #EDF1F4;
        box-shadow: 0px 0px 0px 0px;
        max-width: 900px;
        color: #222;
        position: relative;

        p {
            margin: 0 0 1.7em 0
        } 

        .extras-title {
            margin: 0;
            font-size: 24px;
            font-weight: 600;
            padding-bottom: 20px;
        }
    }

    .card ul {
        width: 100%;
        margin-bottom: 0;
        padding-left: 20px;
        list-style: none;
    }

    .card li {
        padding: 1em 0em 1em 60px;
        display: flex;
        align-items: center;

        &::before {
            display: none;
        }

        i.fa-circle-check {
            font-size: 1.55em;
            margin-right: .3em;
        }

        a:hover {
            color: #50d0ff;
        }
    }

    // .card li::before {
    //     font-family: "Font Awesome 5 Free";
    //     content: "\f00c";
    //     font-size: 12px;
    //     background: #222;
    //     width: 2.2rem;
    //     height: 2.2rem;
    //     border-radius: 50%;
    //     color: white;
    //     text-align: center;
    //     margin-right: 1rem;
    //     display: flex;
    //     align-self: center;
    //     justify-content: center;
    //     top: 2rem;
    //     left: 2rem;
    // }

    .card-wrapper .inner:first-child {
        padding: 40px 10px 40px 70px;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(27,27,27,0.1);
        -moz-box-shadow: 0px 0px 0px 0px rgba(27,27,27,0.1);
        box-shadow: 0px 0px 0px 0px rgba(27,27,27,0.1);
    }

    .features-card {
        padding: 0;
        font-size: 0.9em;
        background-color: #F7FCFE;
        position: relative;
        border: 5px solid #E4F7FF;

        .plan-title {
            padding: 1em;
            color: #222;
            font-size: 16px;
        }
    }

    .features-card ul {
        width: 100%;
        padding-left: 0;
        margin-bottom: 0;
    }

    .features-card ul li:before {
        display: none;
    }

    .features-card li {
        padding: 1em 1.5em 1em 68px;
        background: url('/img/svg/check-circle-filled-blue.svg') left 22px center/24px no-repeat;

        &:nth-child(odd) {
            background-color: #E4F7FF;
        }
    }

    .pricing-column-card {
        padding: 0;
        overflow: hidden;
        font-size: 0.9em;

        >div {
            display: flex;
            >div {
                // background: #F7FCFE;
                padding: 5px;
                flex: 1 0 0;
                overflow:hidden;
                border-left: 5px solid #E4F7FF;
                position: relative;

                &.feature-title {
                    flex: 2 0 0;
                    padding-right: 30px;
                }

                &.highlight + div {
                    border-left: none;
                }

                &:first-child {
                    // white-space: pre-wrap;
                    text-align: left;
                }

                &:last-child {
                    border-right: 5px solid #E4F7FF;
                }
            }


        }

    }

    .feature-row {

        &.title-row {
            border-top: 5px solid #E4F7FF;
            font-weight: 600;
            color: #222;
        }

        &:nth-child(even) {
            background-color: #E4F7FF;
        }

        &:last-child {
            border-bottom:5px solid #E4F7FF;
        }


        i {
            font-size: 20px;
            margin: -4px 0 -6px;
            fill : blue;
        }
        a {
            color: #565656;
            text-decoration: none;
            &:hover {
                opacity: 0.8;
            }
            i {
                position: absolute;
                right: 5px;
                font-size: 20px !important;
                top: 35%;
                opacity: 0.6;
            }
        }

    }

}

.login, .sign-up, {
    display: flex;
    align-items: flex-start;

    #failure-notice {
        display: none;
    }

    .alternate-sign-in {
        >span {
            background: white;
            font-size: 80%;
            font-weight: bold;
            padding: 0 1em;
        }
        margin-top: -50px;
    }

    .sign-in-button {
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        svg { stroke: $primary; }
    }

    .sign-in-via-email {
        font-weight: normal;
        font-size: 16px;
        cursor: pointer;
    }

    >div {
        width: 50%;
        border-radius: 10px;

        @media(max-width: 1050px) {
            width: 100%;
        }

        &.form {
            padding: 5em 8em;
            text-align: center;

            @media(max-width: 700px) {
                padding: 1em 0.5em;
            }

            &.grow {
                flex-grow: 1;
            }
            input, select, textarea {

                &:focus {
                    outline: none;
                    border-color: $primary;
                }
            }

            .sign-up-button {
                cursor: pointer;
                i {
                    line-height: 34px;
                    svg {
                        stroke: white;
                        vertical-align: bottom;
                    }
                }
            }

            .login-links {
                display: flex;
                justify-content: space-between;
                align-items: center;

                input[type=checkbox] {
                    margin-left: 0.3em;
                }

                a:not(.button) {
                    text-decoration: none;

                    svg { 
                        stroke: $primary; 
                        width: 2em;
                        height: 1.5em;
                        vertical-align: middle;
                    }

                }

                &.center {
                    justify-content: center;
                }

                &.right {
                    justify-content: flex-end;
                }
            }

        }
        &.aside {
            margin-top: 100px;

            @media(max-width: 1050px) {
                display: none;
            }

            .card {
                padding: 5em 8em;
                background-color: $primary;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                color: white;
                h1,h2,h3,h4,h5,h6 {
                    color: white;
                }
                p { font-size: 90%; }
                hr {
                    background-color: white;
                }

            }

            .sub {
                padding: 1em 5em;
                text-align: center;
                font-size:90%;

            }
        }
    }

    hr {
        height: 5px;
    }
}

[class*="icon-"] {
    width: 1em;
    height: 1em;
    background-position:cover;
    display: inline-block;

    &.icon-check {
        background: url('/img/svg/check-circle-filled-blue.svg') center no-repeat;
        width: 1em;
        height: 1em;
    }

    &.icon-x {
        background: url('/img/svg/circle-xmark-filled-red.svg') center no-repeat;
        width: 1em;
        height: 1em;
    }

    &.icon-circle {
        background: url('/img/svg/circle-filled-blue.svg') center no-repeat;
        width: 1em;
        height: 1em;
    }
}

.learning-centre {

    .learning-sections {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        >div {
            flex: 0 0 auto;
            width: calc(33.3% - 16px);
            min-width: 380px;

            $padding: 3.5em;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            padding: $padding;
            padding-bottom: 0;
            padding-top: 40px;

            >h3 { 
                margin: 0; 
                margin-bottom: 10px;
            }

            >ul {
                margin: 0 (-$padding) ;
                background-color: $off-white;

                li {

                    &::before { content: none; }

                    &:not(:last-child) {
                        border-bottom: 1px solid #dde8ec;
                    }

                    padding: 1em 19px;

                    a {       
                        display: block;              
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        color: black; 
                        font-weight: bold; 
                        font-size: 18px;
                        
                        &:hover {
                            color: $primary;
                            transition: color .2s;
                            svg {
                                stroke: $primary;
                                transition: stroke .2s;
                            }
                        }
                        svg { 
                            margin-right: 18px;
                            width: 19px;
                            stroke: rgb(153,153,153); 
                        }
                    }
                }
            }

            >hr {
                margin: 0 (-$padding) ;
                height: 5px;
            }

            >a.footer-link {
                display: block;
                text-align: right;
                margin: 0 (-$padding) ;
                background-color: $off-white;
                color: #515151;
                svg {stroke:#515151; font-size: 1.5em; vertical-align: text-bottom; margin-left: 15px;}
                padding: 1em;
                padding-right: 2em;
                font-size: 18px;

                &:hover {
                    color: $primary;
                    transition: color .2s;

                    svg {
                        stroke: $primary;
                        transition: stroke .2s;
                    }
                }
            }

            @media(max-width: 890px) {
                min-width: 300px;
                padding: 1em;
                padding-bottom: 0;

                >ul {
                    margin: 0 -1em;
                }
                >hr {
                    margin: 0 -1em;
                }
                >a.footer-link {
                    margin: 0 -1em;
                }

            }

        }
    }

    .docs-link-panel {
        background-color: #dde8ec;
        border-radius: 10px;
        text-align: center;
    }

    .cta {
        color: black;
        padding: 1em 0;
        p {margin:0;}
        font-size: 110%;
        a.button {margin-left: 2em;}

    }

    .docs-search-panel {
        text-align: center;
        margin-top: 3.5em;

        @media(min-width: 700px) {
            padding: 3.5em 5em;

        }

        .headline, .subtitle {
            font-size: 40px;
            margin: 0px;
        }
        .subtitle {
            color: $primary;
        }

        .search-field {
            margin-top: 2em;
            position: relative;
            svg {
                position: absolute;
                left: 24px;
                top: 26px;
                width: 26px;
                stroke: rgb(181,182,184);
            }

            input {
                font-size: 20px;
                padding-left: 80px;
                background-color: #dde8ec;
                border: 2px solid #dde8ec;
                &:focus {
                    outline: none;
                    border: 2px solid #cdd8dc;;
                }

                &::placeholder {
                    color: rgb(181,182,184);
                };
            }
        }

        .result-type-icons { display: none; }

        .search-results {
            display: none;
            text-align: left;
            a { 
                text-decoration: none; 
                display: block;
                white-space: nowrap;
                overflow: hidden;

                svg {
                    display: inline-block;
                    margin: 0em 1.7em;
                    width: 19px;
                    stroke: rgb(153,153,153);;
                }
                h4 {
                    display: inline-block;
                    font-size: 22px;
                    margin: 0.5em 0;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #dde8ec;
                }
            }

            .no-results {
                text-align: center;
                font-style: italic;
            }

        }

        hr {
            height: 5px;
            border-radius: 2.5px;
        }
    }
}

.slide {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    margin-bottom: 25px;
    >div {
        width: calc(50% - 25px);
    }
}


.sub-hero-links {
    font-size: 22px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 60px;
    flex-wrap: wrap;

    @media(max-width: 600px) {
        font-size: 20px;
    }

    a {
        text-decoration: none;
        cursor: pointer;


        &.button {
            padding: 0.6em 1.5em;
        }
        
        svg {
            width: 1.3em;
            height: 1.3em;
            vertical-align: text-bottom;
            margin-left: 15px;

            &.nut { stroke: #fff; }
            &.arrow { stroke: #fff; }
        }
    }

    &.bottom a.button.unfilled:hover {
        color: #fff !important;
        background-color: #222;
        border-color: #222;
        //transition: color none, stroke none;

        svg.arrow {
            stroke: #fff !important;
        }

    }

    a.button.unfilled:hover {
        color: #222!important;
        background-color: #fff;
        border-color: #fff;
        //transition: color none, stroke none;

        svg.arrow {
            stroke: #222;
        }

    }
    // a[href='#how-it-works'], a[href='#1-minute-intro'] {
    //     color: #999;
    // }
    // .tutorial {
    //     color: #999;
    // }

    margin-top: 40px;
    margin-bottom: 80px;
}

.video-modal-container {
    justify-content: center;
    display: flex; 
    position: fixed;
    top: 0;
    z-index: 1003;
    width: 100%;

    .video-scrim {
        position: fixed;
        z-index: 1003;
        background-color: #000;
        opacity: 0.8;
        -moz-opacity: 0.8;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    #anvil-in-80-video {
        z-index: 1004;
        position: relative;
        margin-top: 20vh;
        z-index: 1004;
        width: 80vw;
        max-width: 1000px;
    }

    .close-video {
        z-index: 1005;
        top: 10%;
        right: 10%;
        cursor: pointer;
        position: absolute;
        z-index: 1005;

        i {
            font-size: 28px;
            color: #fff
        }
    }
}

// @media only screen and (min-width:900px) {
//     .col-hero.home .sub-hero-links a:not(:last-child) {
//                                          margin-right: 200px;
//                                     }
//                                 }

.demo-container {
    display: flex;
    justify-content: space-evenly;

    @media(max-width: 900px) {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column-reverse;
    }


    .design-build-ship {
        float: left;
        display: grid;

        grid-template-columns: 1fr 4fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
        margin-right: 60px;
        margin-top: 40px;
        column-gap: 50px;
        align-items: center;

        //max-height: 500px;

        @media(max-width:1268px) {
            column-gap: 30px;
            margin-left: 100px;
        }

        @media(max-width: 1101px) {
            grid-template-rows: 1fr .8fr 1fr .8fr 1fr .8fr;
            margin-right: 40px;
        }

        @media(max-width: 1082px) {
            margin-left: 60px;
        }

        @media(max-width: 900px) {
            max-width: 600px;
            margin-left: 40px;
        }

        @media(max-width: 600px) {
            grid-template-rows: .8fr 1fr .8fr 1fr .8fr 1fr;

        }

        @media(max-width: 450px) {
            margin-left: 0px;
            max-width: 350px;
            grid-template-rows: .6fr 1fr .6fr 1fr .6fr 1fr;
        }

        #num-design {
            grid-area: 1 / 1 / span 2 / span 1;

            @media(max-width:1268px) and (min-width: 901px) {
                grid-area: 1 / 1 / span 1 / span 1;
            }
        }

        #num-build {
            grid-area: 3 / 1 / span 2 / span 1;

            @media(max-width:1268px) and (min-width: 901px) {
                grid-area: 3 / 1 / span 1 / span 1;
            }
        }

        #num-ship {
            grid-area: 5 / 1 / span 2 / span 1;

            @media(max-width:1268px) and (min-width: 901px) {
                grid-area: 5 / 1 / span 1 / span 1;
            }
        }

        .num-circle {
            background-color: #3e3e3e;
            border-radius: 110px;
            width: 110px;
            height: 110px;
            margin-top: 4px;

            @media(max-width:1268px){
                width: 60px;
                height: 60px;
            }

            @media(max-width: 900px) {
                width: 90px;
                height: 90px;
            }
        }

        div.number {
            font-size: 92px;
            font-weight: bold;
            line-height: 1.3em;
            margin-top: -112px;

            @media(max-width:1268px) {
                font-size: 48px;
                margin-top: -59px;
            }

            @media(max-width: 900px) {
                font-size: 58px;
                margin-top: -80px;
            }

            &.one {
                margin-left: 37px;

                @media(max-width:1268px) {
                    margin-left: 22px
                }

                @media(max-width: 900px) {
                    margin-left: 34px;
                }
                
            }

            &.two {
                margin-left: 30px;

                @media(max-width:1268px) {
                    margin-left: 17px
                }

                @media(max-width: 900px) {
                    margin-left: 29px;
                }
            }

            &.three {
                margin-left: 28px;

                @media(max-width:1268px) and (min-width: 901px) {
                    margin-left: 16px
                }
            }
        }

        .heading {
            font-size: 36px;
            margin-top: 0px;
            margin-bottom: 0px;
            align-self: end;

            @media(max-width:1268px) {
                align-self: center;
            }

            @media(max-width:900px) {
                align-self: end;

            }

            @media(max-width:600px) {
                font-size: 28px;
            }
        }

        .description {
            font-size: 18px;
            line-height: 30px;
            margin: 0px;
            align-self: start;

            &#p-design {
                grid-area: 2 / 2 / span 1 / span 1;

                @media(max-width:1268px) and (min-width:901px) {
                    grid-area: 2 / 1 / span 1 / span 2;
                }
            }

            &#p-build {
                grid-area: 4 / 2 / span 1 / span 1;

                @media(max-width:1268px) and (min-width:901px) {
                    grid-area: 4 / 1 / span 1 / span 2;
                }
            }

            &#p-ship {
                grid-area: 6 / 2 / span 1 / span 1;

                @media(max-width:1268px) and (min-width:901px) {
                    grid-area: 6 / 1 / span 1 / span 2;
                }
            }

            @media(max-width:1268px){
                font-size: 16px;
                margin-bottom: 20px;
            }

            @media(max-width:1102px) and (min-width: 901px) {
                margin-bottom: 10px;
            }


            @media(max-width:900px) {
                font-size: 18px;
            }

            @media(max-width:600px) {
                font-size: 16px;
                line-height: normal;
            }

            @media(max-width:541px) {
                margin-bottom: 10px;
            }

            @media(max-width:450px) {
                font-size: 14px;
            }
        }
    }

    // #animation {
    //     @media (max-width: 1120px) {
    //         width: 100%;
    //         height: auto;
    //     }
    // }

    #animation {
        float: right;
        position: relative;
        max-width: 700px;
        box-sizing: border-box;
        overflow-x: visible;
        height: 100%;
        align-self: center;

        @media(max-width: 1165px) {
            max-width: 600px;
        }

        @media(max-width: 900px) {
            width: 100%;
            height: auto;
        }

    }
}
//TODO: make responsive

h4.section-heading {
    text-align: center;
    margin: auto;
    position: relative;
    >span {
        color: white;
        background-color: $primary;
        padding: .2em 1em;
    }
}

.gray-bar {
    background-color: #ecf1f4;
    min-height: 250px;
    margin-top: -10px; //TODO: fix on mobile
    margin-bottom: 60px;

    h4.section-heading {
        bottom: -85px;
    }
//TODO: fix this responsiveness nightmare
    @media(max-width: 1024px) {
        min-height: 230px;
        margin-top: -11px;

    }

    @media(max-width: 685px) {
        min-height: 280px;
    }

    @media(max-width: 591px) {
        h4.section-heading {
            bottom: -59px;
        }
    }

    @media(max-width: 517px) {
        h4.section-heading {
            bottom: -75px;
        }
    }

    .gray-line {
        max-width: 1000px;
        width: 60%;
        height: 2px;
        background-color: #C6C6C6;
        margin: 0 auto;
    }


    .top-quote-container{
        max-width: 850px;
        position: relative;
        margin: 0 auto;
        display: flex;
        align-items: center;
        gap: 40px;


        @media(max-width:700px) {
            padding: 0 20px;
        }

        .quote-arrow-container{
            flex: 0 0 0;
            width: 50px;

            @media(max-width:700px) {
                display: none;
            }


            .arrow-button {
                width: 40px;
                height: 40px;
                display: block;

                text-align: center;

        
                >i {
                    margin-top: 12px;
                    color: #555;
                }

                // &.left {
                //     padding-right: 60px
                // }

                // &.right {
                //     padding-left: 60px;
                // }

            }

        }



    }

    #quote-scroller {
        display: flex;
        flex: 1 0 0;
        overflow-x: auto;
        position: relative;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        &>div {
            width: 100%;
            flex: 0 0 auto;
        }

        .top-quote {
            text-align: center;

            padding-top: 80px;
            // padding-bottom: 20px;
            font-weight: 600;
            font-size: 18px;

            @media(max-width: 600px) {
               font-size: 16px; 
            }

        }

        .author {
            text-align: center;
            margin-bottom: 0px;
        }

        .company {
            text-align: center;
            font-weight: bold;
        }

        

    
    }


    .logo-container {
        max-width: 1300px;
        display: flex;
        gap: 18px;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        margin: auto;
        padding: 60px 0px 120px;

        .logo {
            max-height: 22px;

            &.basispoint {
                max-height: 38px; 
            }

            &.mark-one {
                max-height: 80px; 
            }

            &.isaac-physics {
                max-height: 90px;
            }

            &.bakertilly {
                max-height: 40px;
            }

            &.bayer {
                max-height: 90px;
            }

            &.deloitte {
                max-height: 28px;
            }

        }

        @media(max-width: 1024px) {

            .logo {
                max-height: 20px;
            
                &.basispoint {
                    max-height: 35px;
                }

                &.mark-one {
                    max-height: 75px;
                }

                &.isaac-physics {
                    max-height: 80px;
                }

                &.bayer {
                    max-height: 80px;
                }

                &.deloitte {
                    max-height: 25px;
                }
            }
        }
    }

}

.browser {
    border-width: 15px 3px 3px 3px;
    border-style: solid;
    border-color: #5C5C5C;
    border-radius: 3px;
    object-fit: cover;
}
.browser-button {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    position: relative;


    &.close {
        background-color: #ef4642;
      }
      
    &.min {
        background-color: #ff9900;
    
      }
      
    &.exp {
        background-color: #7edd70;
      }
  }
  


.why-anvil-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 3fr;
    grid-row-gap: 48px; 
    grid-column-gap: 68px;
    height: 800px;
    margin-bottom: 60px;
    // padding-top: 40px;


    @media(max-width: 1200px) {
        grid-template-columns: 1fr;
        grid-template-rows: 4fr 5fr 8fr;
        margin: 0 auto;
        max-width: 700px;
        min-height: 1600px;
    }

    @media(max-width: 600px) {
        grid-template-columns: 1fr;
        grid-template-rows: 4fr 4fr 8fr;
    }


    .why-anvil-card {
        border-radius: 5px;
        box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
        padding: 0px 40px 0px 40px;
        overflow: hidden;
        position: relative;

        .learn-more {

            @media(max-width: 400px) {
                font-size: 14px;

                svg {
                   width: 1em;
                   height: 1em;
                }
            }
        }


        .sub-card {
            position:relative;
        }

        a {
            // position: relative;
            text-decoration: none;

        }
        svg {
            width: 1.3em;
            height: 1.3em;
            position: relative;
            vertical-align: text-bottom;
            margin-left: 15px;

            &.arrow { stroke: $primary; }
        }

        h2 {
            @media(min-width: 501px) {
                font-size: 2.35em;
            }
        }

        p {
            margin-bottom: 10px;


            @media(max-width: 500px) {
                font-size: 14px;
            }
            @media(max-width: 387px) {
                font-size: 12px;
            }
        }
        }


    #powerful { 
        display: flex;
        grid-area: 1 / 1 / span 1 / span 1;


        @media(max-width: 600px) {
            flex-wrap: wrap;
            justify-content: space-around;
        }

        img {
            // position: relative;
            max-width: 330px;
            margin-top: 110px;
            margin-left: 40px;

            @media(max-width: 1200px) {
                max-width: 450px;
            }

            @media(max-width: 600px) {
                margin-top: 20px;
                max-width: 350px;
                margin-left: 0px;
            }

            @media(max-width: 450px) {
                max-width: 300px;
            }

        }

        p {
            max-width: 200px;

            @media(max-width: 1200px) {
                max-width: 500px;
            }

        }

        a {            
            top: -215px;
            @media(max-width: 582px) {
                top: -20px;
            }
        }

        .close {
            top: -241px;
            left: 50px;

            @media(max-width: 1200px) {
                top: -322px;
            }

            @media(max-width: 600px) {
                left: 12px;
                top: -255px;
            }

            @media(max-width: 450px) {
                top: -220px;
            }

        }

        .min {
            top: -246px;
            left: 58px;

            @media(max-width: 1200px) {
                top: -327px;
            }

            @media(max-width: 600px) {
                left: 20px;
                top: -260px;
            }

            @media(max-width: 450px) {
                top: -225px;
            }
        }

        .exp {
            top: -251px;
            left: 66px;

            @media(max-width: 1200px) {
                top: -332px;
            }

            @media(max-width: 600px) {
                left: 28px;
                top: -265px;
            }

            @media(max-width: 450px) {
                top: -230px;
            }
        }
    }

    #simple {
        grid-area: 1 / 2 / span 2 / span 1;
        display: grid;
        column-gap: 20px;
        row-gap: 60px;
        grid-template-columns: 1fr 1fr;
        // grid-template-rows: 2fr 1fr 2fr;
        grid-template-rows: 1fr 1fr;

        @media(max-width: 1200px) {
            grid-area: 3 / 1 / span 1 / span 1;
        }

        @media(max-width: 472px) {
            grid-template-columns: 1fr;
            grid-template-rows: 2fr 3fr 2fr;
            // max-height: 800px;
            row-gap: 30px;
        }

        @media(max-width: 300px) {
            row-gap: 20px;
        }

        #user-auth {
            max-width: 200px;
            position: relative;
            justify-self: center;
            align-self: end;
            grid-area: 1 / 2 / span 1 / span 1;

            box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
            border-radius: 5px;

            @media(max-width: 472px) {
                grid-area: 2 / 1 / span 1 / span 1;
                align-self: center;
                justify-self: center;
            }

        }

        .simple-paragraph-one {
            max-width: 250px;

            @media(max-width: 472px) {
                max-width: none;
            }
        }

        .simple-paragraph-two {
            grid-area: 2 / 1 / span 1 / span 2;

            @media(max-width: 472px) {
                grid-area: 3 / 1 / span 1 / span 1;

            }

        }

        #mapbox {
            max-width: 516px;
            margin-top: 20px;
            justify-self: center;

            // @media(max-width: 1200px) {
            //     max-width: 415px;
            // }

            @media(max-width: 472px) {
                display: none;
            }
        }

        .browser-button {

            @media(max-width: 472px) {
                display: none;
            }

            &.close {
                top: -282px;
                left: 10px;
            }
    
            &.min {
                top: -287px;
                left: 18px;
            }
    
            &.exp {
                top: -292px;
                left: 26px;
            }
        }
    }

    #pythonic {
        // height: 450px;
        grid-area: 2 / 1 / span 1 / span 1;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr 1fr;
        column-gap: 20px;

        @media(max-width: 1200px) {
            grid-area: 2 / 1 / span 1 / span 1;
        }

        @media(max-width: 600px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
        }

        p {
            max-width: 300px;

            @media(max-width: 600px) {
                max-width: 500px;
            }

            @media(max-width: 400px) {
                max-width: 250px;
            }

            @media(max-width: 300px) {
                max-width: 200px;
            }

        }

        .python-packages {
            grid-area: 1 / 2 / span 2 / span 1;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr  1fr 1fr;
            align-items: center;
            // justify-items: center;

            @media(max-width: 600px) {
                display: none;
            }
        }

        .code-editor {
            grid-area: 2 / 1 / span 1 / span 1;
            max-width: 350px;
            background-color: #242424;
            border-radius: 5px;
            padding: 20px 28px;
            margin-top: 15px;
            box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);

            pre {
                color: #fff;
                font-size: 12px;
                margin: 0px;
            }
        }

        .browser-button {
            width: 7px !important;
            height: 7px !important;
            border-radius: 7px !important;
        }
    
        .close {
            top: -242px;
            left: -12px;
        }
    
        .min {
            top: -249px;
            left: -1px;
        }
    
        .exp {
            top: -256px;
            left: 10px;
        }


        .python-lib-logo {
            background-color: #EDF1F4;
            padding: 5px 12px 5px 12px;
            position: relative;
            border-radius: 5px;
        }

        .python-lib-logo#plotly {
            max-width: 120px;
            grid-row: 2;
            justify-self: start;
        }

        .python-lib-logo#pandas {
            max-width: 120px;
            grid-row: 3;
            justify-self: end;
        }

        .python-lib-logo#matplotlib {
            max-width: 130px;
            grid-row: 4;
            padding-top: 10px;
            padding-bottom: 10px;
            z-index: -1;
            justify-self: start;

        }

        .python-lib-logo#numpy {
            max-width: 120px;
            grid-row: 5;
            justify-self: end;
            
        }

        .python-lib-logo#tensorflow {
            max-width: 140px;
            grid-row: 6;
            padding: 2px 0px;
            z-index: -1;
            justify-self: start;

        }
    }
}

.feature-container {
    background-color: #1b1b1b;
    background: linear-gradient(rgba(27, 27, 27, 0.98), rgba(27, 27, 27, 0.98)), url(/img/home/features-background-1.png);
    background-size: cover;
    width: 100%;
    padding: 300px 50px;
    margin-top: 100px; 
    // margin-bottom: 60px;
    position: relative;
    overflow: hidden;

    .feature-list {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;

            //TODO: fix bottom links on mobile
        #feature-ctas {
            .ctas {
                margin-top: 0px;
                padding-bottom: 0px;

                a {
                    @media(max-width: 445px) {
                        font-size: 20px;
                    }

                    @media(max-width: 376px) {
                        font-size: 18px;
                    }

                    color: #7A7A7A;
                    font-size: 28px;

                    svg {
                        stroke: #7A7A7A;
                        margin-left: 10px;

                        @media(max-width: 376px) {
                            width: 28px;
                            height: 28px;
                        }
                    }
                }

                a:not(:first-child) {
                    margin-left: -20px;

                }

                a:first-child {
                    margin-right: 20px;
                }

                &.small {
                    display: flex;
                    flex-wrap: wrap;
                    a {
                        @media(max-width: 445px) {
                            font-size: 14px;
                        }
                        font-size: 18px;
                        color: #7A7A7A;
                        svg {
                            @media(max-width: 445px) {
                                width: 16px;
                                height: 16px;
                            }
                            stroke: #7A7A7A;
                            margin-left: 10px;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            &.highlighted-ctas {
                .ctas {
                    a {
                        color: $primary;
                        svg {
                            stroke: $primary;
                        }
                    }
                    &.small {
                        a {
                            color: white;
                            svg {
                                stroke: white;
                            }
                        }
                    }
                }
            }
        }


        h1 {
            // color: #7A7A7A !important;
            letter-spacing: 2px;
            margin-bottom: 30px;
            line-height: 98px;

            @media(max-width: 717px) {
                line-height: 58px;
            }

            @media(max-width: 500px) {
                font-size: 1.5em;
                line-height: 42px;
            }

            >.feature {
                display: inline-block;
                color: #7A7A7A;

                @media(max-width: 717px) {
                    display: inline;
                }
            }

        }

        .highlighted {
            color: #fff !important;
      
        &.underline {
            text-decoration: underline 5px $primary;
          }
          }


    }
}


// p.subheading {
//     font-size: 19px;
//     margin-bottom: 60px;

// }


.gallery-container {
    background-color: #ecf1f4;
    padding: 20px 0px 80px;

    h1, >p {
        text-align: center;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;

        &.subheading {
            font-size: 19px;
            margin-bottom: 8px;
            margin-top: -.83em;
        }
    }

    h1 {
        padding-top: 60px;
    }

    .ctas {
        margin-top: 0px;
        padding-bottom: 60px;
        text-align: center;
        a {
            color: $primary;
            svg {
                stroke: $primary;
            }
        }
    }
    .gallery {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        // flex-wrap: wrap;
        position: relative;
        gap: 24px;
        
        @media(max-width: 1200px) {
            grid-template-columns: 1fr 1fr;
        }
        @media(max-width: 800px) {
            grid-template-columns: 1fr
        }

        .gallery-item {
            position: relative;
            display: flex;
            flex-direction: column;
            text-decoration: none;
            align-items: center;

            .caption {
                text-align: center;
                color: #555;
                .category {
                    font-size: 18px;
                    font-weight: bold;
                    margin: 8px 0px 6px 0px;
                  }
            }

            &:hover {
                .gallery-img {
                    filter: drop-shadow(0 0 1rem #2AB1EB);
                }
            }

            .gallery-img {
                // width: 380px;
                width: 100%;
                max-height: 266px;
                object-fit: cover;

                &.mobile {
                    
                    width: 130px;
                    height: 266px;
                    border-width: 4px 4px 4px 4px;
                    border-radius: 14px;
                    border-style: solid;
                    border-color: #5C5C5C;

                    @media (max-width: 400px) {
                        width: 91px;
                        height: 186px;
                    }

                }
                &:active {
                    opacity: 0.7;
                }
            }

            .mobile-phone {
                position: absolute;

                .mobile-top {
                    width: 60px;
                    height: 0px;
                    top: -274px;
                    left: 34px;
                    position: relative;
                    border-radius: 4px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 10px solid #5C5C5C;
    
                    @media (max-width: 400px) {
                        width: 42px;
                        left: 24px;
                        top: -195px;
                        border-radius: 3px;
                        border-left: 3px solid transparent;
                        border-right: 3px solid transparent;
                        border-top: 7px solid #5C5C5C;
                    }
                }
    
                .mobile-camera {
                    width: 4px;
                    height: 4px;
                    border-radius: 4px;
                    position: relative;
                    background-color: #888E64;
                    top: -281px;
                    left: 75px;
    
                    @media (max-width: 400px) {
                        width: 3px;
                        height: 3px;
                        border-radius: 3px;
                        top: -200px;
                        left: 55px;
                    }
                }
    
                .mobile-bar {
                    width: 50px;
                    height: 2px;
                    border-radius: 2px;
                    position: relative;
                    background-color: #818181;
                    top: -34px;
                    left: 40px;
    
                    @media (max-width: 400px) {
                        width: 40px;
                        top: -30px;
                        left: 27px;
                    }
                }
    
                .mobile-side-button {
                    width: 5px;
                    position: relative;
                    background-color: #5C5C5C;
                    top: -275px;
                    @media (max-width: 350px) {
                        top: 45px;
                    }
    
                    &.power {
                        height: 30px;
                        left: 127px;
                        top: -248px;
    
                        @media (max-width: 400px) {
                            height: 28px;
                            left: 87px;
                            top: -170px;
                        }
                    }
    
                    &.vol {
                        height: 20px;
                        left: -1px;
    
                        @media (max-width: 400px) {
                            height: 18px;
                            top: -200px;
                        }
    
                        &.down {
                            top: -266px;
                            @media (max-width: 400px) {
                                height: 18px;
                                top: -192px;
                            }
                        }
    
                    }
    
                }
            }
            

            // #feedforward {
            //     .mobile-top {
            //         left: 75px;

            //         @media (max-width: 350px) {
            //             left: 55px;
            //         }
            //     }
    
            //     .mobile-camera {
            //         left: 115px;

            //         @media (max-width: 350px) {
            //             left: 85px;
            //         }
            //     }
    
            //     .mobile-bar {
            //         left: 80px;

            //         @media (max-width: 350px) {
            //             left: 56px;
            //         }
            //     }
    
            //     .mobile-side-button {
            //         &.power {
            //             left: 169px;

            //             @media (max-width: 350px) {
            //                 left: 117px;
            //             }
            //         }
    
            //         &.vol {
            //             left: 40px;
            //         }
            //     }

            // }

            .browser-button {

                @media (max-width:400px) {
                    display: none;
                }

                &.close {
                    top: -272px;
                    left: 9px;
                  }
                  
                &.min {
                    top: -277px;
                    left: 17px;
                  }
                  
                &.exp {
                    top: -282px;
                    left: 25px;
                  }
              }

        }

    }

}


// .index-main {
//     text-align: center;


// h2.section-heading {
//     font-size: 28px;
//     color: white;
//     display: block;
//     width: calc(100% - 40px); // padding on wrapper
//     position: absolute;
//     margin: 0 ;
//     top: -20px;
//     box-sizing: border-box;

//     >span {
//         background: $primary;
//         padding: 0.2em 1em;
//     }
// }

// .how-it-works>h2.section-heading {
//     top: -95px;
// }


    // .design-build-ship-scroller-container {
    //     max-width: 100vw;
    //     position: relative;
    //     overflow-x: hidden;
    // }

    // .design-build-ship-image {
    //     width: 100%;
    //     height: 500px * 0.8;
    //     margin-top: 90px;

    //     img {
    //         width: 630px * 0.8;

    //         @media(max-width: 860px) {
    //             width: 350px;
    //             left: -350px;

    //             &.current {
    //                 left: calc(50% - (175px)) !important;
    //             }
    //         }

    //         position: absolute;
    //         left: -630px * 0.8;
    //         opacity: 0;
    //         transition: left 0.5s, opacity 0.5s;
    //         //transform: rotateX(0deg) rotateY(0deg) rotateZ(1deg);
    //     }

    //     img.current {
    //         left: calc(50% - (315px * 0.8));
    //         opacity: 1;

    //         ~ img {
    //             right: initial;
    //             left: 100%;
    //             opacity: 0;
    //         }
    //     }

    // }

    // .design-build-ship-progress {
    //     position: relative;
    //     width: 67%;
    //     height: 0;
    //     border-top: 2px dotted #d7d7d7;
    //     margin: 40px auto;

    //     display: flex;
    //     justify-content: space-between;

    //     .progress-bar {
    //         position: absolute;
    //         z-index: 1;
    //         left: 0;
    //         height: 4px;
    //         top: -3px;
    //         background: $primary;
    //         transition: width 0.5s;
    //     }

    //     .design.current ~ .progress-bar {
    //         width: 0%;
    //     }

    //     .build.current ~ .progress-bar {
    //         width: 50%;
    //     }


    //     .ship.current ~ .progress-bar {
    //         width: 100%;
    //     }


    //     .dot {
    //         z-index: 2;
    //         width: 0;
    //         position: relative;

    //         &::after {
    //             content:'';
    //             width: 16px;
    //             height: 16px;
    //             border-radius: 10px;
    //             background: $primary;
    //             position: absolute;
    //             top: -9px;
    //             left: -8px;
    //             transition: width 0.5s, height 0.5s, border-radius 0.5s, border-width 0.5s, background-color 0.5s, left 0.5s, top 0.5s;
    //         }

    //         &.current::after {

    //             content: '';
    //             width: 32px;
    //             height: 32px;
    //             border-radius: 16px;
    //             top: -17px;
    //             left: -16px;
    //             background: white;
    //             border: 4px solid $primary;
    //         }

    //         &.current ~ .dot::after {
    //             background: #d7d7d7;
    //         }
    //     }




    // }

    .index-cols {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        $col-spacing: 30px;

        >div {
            flex: 1 0 200px;
            margin: 0 $col-spacing;
        }

        // &.design-build-ship>div {
        //     padding: 0 40px;

        //     p {
        //         color: #666;
        //     }

        //     @media(max-width: 900px) {
        //         min-width: 500px;
        //     };
        //     @media(max-width: 500px) {
        //         min-width: initial;
        //     }
        // }
    }

    .ctas {

        margin-top: 50px;
        padding-bottom: 200px;

        a {
            text-decoration: none;
            color: #666;

            font-size: 1.56em;

            @media(max-width: 400px) {
                font-size: 1em;
            }

            &:not(:first-child) {
                margin-left: 50px;

                svg {
                    margin-left: 20px;
                }
            }
        }
        svg { 
            width: 30px;
            height: 30px;
            vertical-align: text-bottom;
            stroke: #666;
        }

        .try-anvil-cta {
            color: white;
            i { top: 0 !important; }
            svg { 
                stroke: $primary; 
            }
        }

    }

    .anvil-in-1-minute {
        background: #1b1b1b;
        color: white;
        padding-bottom: 6em;

        h1 {
            padding-top: 3em;
            margin-bottom: 2.5em;
            color: white;
        }
        h2,h3,h4 { color: white; }
        p {color: #999;}
    }

    /* Amateurishly smashed together from .why-anvil */
    .anvil-for {
        padding-bottom: 200px;

        h1 {
            margin-bottom: 2.5em;
        }

        .index-cols>div {
            text-align: left;
            padding: 0 40px;

            &>a {
                display: block;
                min-height: 18.1em;
                @media(max-width:1150px) {
                    min-height: 20.1em;
                }
                color: inherit;
                text-decoration: none;
                &:hover h4 {
                    text-decoration: underline;
                }
            }

            svg.icon {
                width: 70px;
                height: 70px;
            }
            svg {
                stroke: $primary;
            }
            &:nth-child(2) svg {
                stroke: $secondary;
            }
            &:nth-child(3) svg {
                stroke: $tertiary;
            }

            @media(max-width: 950px) {
                min-width: 500px;
                text-align: center;
                padding: 0 100px 100px;
                &>a { min-height: initial; }
            };
            @media(max-width: 600px) {
                min-width: initial;
                padding: 0 30px 100px;
            }

        }

        .ctas {

            padding-bottom: 0;
            margin-top: 18px;

            .button {
                color: white;
                margin-left: 0;
                font-size: 1.2em;
            }

            .try-anvil-cta {
                color: #666;
                background: white;
            }

            .pricing {
                color: $primary;

                svg { stroke: $primary; }
            }
        }

        .classroom-use {
            padding: 60px 70px 0;
            text-align: left;
            font-style: italic;

        }
    }

    /* Amateurishly smashed together from the above */
    .open-source {
        h1 {
            margin-bottom: 1.5em;
        }

        padding: 0 20px 200px;
        max-width: 1120px;
        margin: 0 auto;
        @media(min-width: 768px) {
            padding: 0 40px 200px;
        }

        svg.icon {
            width: 140px;
            height: 140px;
            flex-grow: 0;
            flex-shrink: 0;
        }
        svg {
            stroke: $secondary;
        }

        .one-row-callout-with-icon {
            display: flex;
            flex-direction: horizontal;
            flex-align: start;
            @media(min-width: 500px) {
                font-size: 1.2em;
            }
            text-align: left;
            width: 100%;

            .text {
                @media (min-width: 500px) {
                    padding-left: 20px;
                }
                @media(min-width: 768px) {
                    padding-top: 35px;
                    padding-left: 40px;
                }
            }
        }


        .ctas {
            @media(min-width: 768px) {
                text-align: right;
            }

            padding-bottom: 0;
            margin-top: 18px;

            .button {
                color: white;
                margin-left: 0;
                font-size: 1.2em;
            }

        }
    }

    /* Amateurishly smashed together from .trusted-by */
    .compatible-with {
        position: relative;
        padding: 0 20px 250px;

        h1 {
            margin-bottom: 1em;
        }

        .text {
            @media(min-width: 500px) {
                font-size: 1.2em;
            }
            margin-bottom: 1.5em;
        }

        .logos {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            $logo-spacing: 48px;
            //margin: 0 (-$logo-spacing);

            >* {
                margin: 20px $logo-spacing;
                max-width: 150px;
                max-height: 30px;
            }

            span.and-more {
                white-space: nowrap;
                font-size: 24px;
                //font-weight: bold;
            }
        }
        .ctas {
            padding-bottom: 0;
        }
    }


    .why-anvil {
        background: #1b1b1b;
        color: white;
        padding-bottom: 6em;

        h1 {
            padding-top: 3em;
            margin-bottom: 2.5em;
            color: white;
        }
        h2,h3,h4 { color: white; }
        p {color: #999;}

        .index-cols>div {
            text-align: left;
            padding: 0 40px;
            
            svg {
                width: 40px;
                height: 40px;
                stroke: $primary;
            }
        }

        .ctas {
            margin-top: 50px;
            padding-bottom: 250px;

            .try-anvil-cta {
                color: #666;
                background: white;
            }

            .pricing {
                color: $primary;

                svg { stroke: $primary; }
            }
        }
    }

    .case-study {
        position: relative;
        // margin-top: -110px;
        padding-bottom: 8em;

        .case-study-container {
            display: flex; 
            justify-content: space-evenly; 
            flex-wrap: wrap;

            @media(max-width: 1220px) {
                column-gap: 200px; //force flex box to collapse to one column
            }
        }


        // h2.section-heading {
        //     left: calc(50% + 100px);
        //     width: 300px;
        //     text-align: left;
        //     top: -50px;
        // }

        @media(max-width: 1400px) {
            .case-study-cols .quote::after {
                width: 150px;
                height: 150px;
            }
        }
        @media(max-width: 1020px) {
            .case-study-cols .quote::after {
                width: 150px;
                height: 150px;
                bottom: -30px;
            }
        }

        @media(max-width: 860px) {

            h2.section-heading {
                left:0;
                top: -60px;
                width: calc(100% - 40px);
                text-align: center;
            }

            .case-study-cols {
                .quote {
                    margin-top: 0;
                }
            }
        }

        @media(max-width: 600px) {
            .quote::after {
                display: none;
            }
        }
                        

        svg.concave { fill: #f7fcff; stroke: none; }


        .case-study-cols {
            display: flex; //change to grid?
            flex-wrap: wrap;
            align-items: flex-start;
            max-width: 500px;
            // width: calc(100% - 40px);
            padding-bottom: 60px;
            >div {
                // @media(min-width: 860px) {
                //     flex: 1 0 50%;
                // }
                //min-width: 300px;
            }
        }
        .quote {
            background: #ecf1f4;
            text-align: left;
            position: relative;

            &.colette, &.steven {
                padding: 2em 2em 2em 7em;
                @media(max-width: 1020px) {
                    padding: 4em;
                }
            }

            &.anders, &.teel {
                padding: 2em 7em 2em 2em;
                @media(max-width: 1020px) {
                    padding: 4em;
                }
            }


            @media(max-width: 400px) {
                padding: 1em;
                font-size: 80%;
            }

            h3 {
                text-decoration: underline $primary 3px;
                font-size: 28px;
            }

            p { 
                color: #777; 
                // margin-top: 2em;
                margin-bottom: 0;
                // max-width: 50%;
            }

            .ctas {
                font-size: 14px;
                padding-bottom: 0px;
                margin-top: 20px;

                a {
                    @media(max-width: 400px) {
                        font-size: 20px;
                    }
                    color: $primary;
                    svg { stroke: $primary; }


                }
            }

            h4 {
                margin: .8em 0px .8em 0px;
                font-size: 18px;
            }

            &::after {
                content: ' ';
                border: 10px solid white;

                width: 200px;
                height: 200px;
                position: absolute;
                bottom: 100px;
            }

            &.colette::after {
                background: url(/img/colette.jpg) no-repeat center/cover;
                left: -120px;
            }
            
            &.steven::after {
                background: url(/case-studies/img/basispoint/steven-thumbnail.png) no-repeat center/cover;
                left: -120px;            
            }

            &.anders::after {
                background: url(/blog/img/rikstv-full-interview/anders-headshot.jpg) no-repeat center/cover;
                right: -120px;
            }

            &.teel::after {
                background: url(/blog/img/podcast/09-fairshake-offices.png) no-repeat center/cover;
                right: -120px;
            }

            @media(max-width: 1400px) {
                &.colette::after, &.steven::after {
                    left: -60px;
                }
    
                &.anders::after, &.teel::after {
                    right: -60px;
                } 
            }

            @media(max-width: 1020px) {
                &.colette::after, &.steven::after {
                    left: 380px;
                }
    
                &.anders::after, &.teel::after {
                    right: -30px;
                }
            }



        }
        // .details {

        //     padding-left: 30px;
        //     &:nth-child(even) {
        //         @media(min-width: 600px) {
        //             padding-left: 100px;
        //         }
        //         @media(max-width: 400px) {
        //             padding-left: 10px;
        //         }
        //     }
        //     &:nth-child(odd) {
        //         @media(min-width: 600px) {
        //             padding-right: 100px;
        //         }
        //         @media(max-width: 400px) {
        //             padding-right: 10px;
        //         }
        //     }
        //     text-align: left;

        //     // .ctas {
        //     //     margin-top: 50px;
        //     //     padding-bottom: 50px;
        //     //     a {
        //     //         @media(max-width: 400px) {
        //     //             font-size: 20px;
        //     //         }
        //     //         color: $primary;
        //     //         svg { stroke: $primary; }
        //     //     }
        //     // }
        // }
    }

    .trusted-by {
        position: relative;
        background: white;

        .logos {
            padding-top: 100px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            $logo-spacing: 48px;
            //margin: 0 (-$logo-spacing);

            >* {
                margin: 20px $logo-spacing;
                max-width: 150px;
                max-height: 30px;
            }

            span.and-more {
                white-space: nowrap;
                font-size: 24px;
                //font-weight: bold;
            }
        }
    }


.about-us {
    .people {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        >div {
            flex: 0 0 0;
            padding: 2em 1em 0; /* 2em 5em 0; */
        }

        img.img-circle {
            width: 230px;
            height: 230px;
            border-radius: 50%;
        }

        img.img-circle:hover {
            opacity: 1;
        }

        .container {
          position: relative;
          width: 100%;
        }

        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 230px;
          height: 230px;
          border-radius: 50%;
          opacity: 0;
          transition: .1s ease;
          background-color: #1BB0EE;
        }

        .container:hover .overlay {
          opacity: 1;
        }

        .text {
          color: white;
          font-size: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          text-align: center;
        }

        h4 {
            white-space: nowrap;
        }

        .team-member {
            text-align: center;
        }

    }

    .social-buttons {
        display: none;
    }
}

.features {
    padding-top: 80px;

    .feature {

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        width: 100%;

        >.details {
            flex: 0 0 60%;
            @media(max-width: 742px) {
                min-width: 300px;
                text-align: center;
                order: 2;
            }
        }

        >img {
            width: 0;
            flex: 0 0 40%;
            border-radius: 10px;
            border: 5px solid #dde8ec;
            min-width: 280px;
            @media(max-width: 742px) {
                min-width: 300px;
                order: 1;
            }
        }
    }
}

.case-study-quote {
    display: flex;
    // width: 100%;
    max-width: 960px;
    align-items: center;
    margin: auto;

    >div.quote {
        flex: 1 0 0;
        min-height: 200px;
        z-index: 1;
        padding-left: 120px;
        position: relative;

        .inner, p {
            font-weight: bold;
            margin-top: 8px;
            margin-bottom: 1.7em;
            color: black;
            font-size: 20px;
            line-height: 1.4;
        }

        &::before {
            content: '';
            position: absolute;
            height: calc(100% - 4em);
            top: 2em;
            left: 90px;
            width: 6px;
            border-radius: 3px;
            background: $primary;
        }

        h4, h5 {
            font-size: 18px;
            color: #777;
            font-weight: bold;
            margin-bottom: 0;
            margin-top: 0
        }

        h5 {
            font-weight: normal;
        }

        .quo {
            color: $primary;
            font-size: 36px;
            font-family: Times, serif;
            line-height: 14px;
            position: relative;
            top: 5px;
        }
    }

    div.img {
        background: center/cover;
        width: 150px;
        height: 150px;
        border: 10px solid white;
        z-index: 2;
        margin-right: -70px;
    }

    &.img-top {
        align-items: start;
        div.img {
            margin-top: 40px;
        }
    }

    @media(max-width:600px) {
        flex-direction: column;
        >div.quote {
            margin-top: 0;
            padding-left: 1.5em;
            padding-top: 120px;
            min-height: initial;
            &::before {
                width: calc(100% - 4em);
                height: 6px;
                left: 2em;
                top: 90px;
            }
        }
        div.img {
            margin-right: 0px;
            margin-bottom: -70px;
        }
    }

}

// .wrapper-medium {
//     .case-study-quote {
//         div.img {
//             background: center/cover;
//             width: 150px;
//             height: 150px;
//             border: 10px solid white;
//             z-index: 2;
//             margin-right: -70px;
//         }
//     }
// }

.contact-form {
    text-align: center;

    a.send-message {
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        text-decoration: none;
        svg {
            stroke: $primary;
            width: 2em;
            height: 1.5em;
            vertical-align: middle;
        }
    }

    .right {
        text-align: right;
    }
}

.sharing-links {
  display: flex;
  justify-content: space-evenly;
  i {
    font-size: 3.1em;
  }
}

.sharing-links.mobile {
    @media  (max-width: 1276px) {
        justify-content: flex-end;
        z-index: 1;
        i {
            font-size: 1.5em;
            padding: 10px;
        }
    }
    @media  (min-width: 1276px) {
        display: none;
    }
}
    

.header-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card.chapter {
  border: 5px solid #1bb0ee;
  background-color: #f5f5f5;

  .button {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
  }

  h3 {
    .icon-check {
      display: none;
      float: right;
      font-size: 1.55em;
    }
  }
  &.tutorial-chapter-complete {
    border: 5px solid #dde8ec;

    .mark-complete {
        display: none;
    }
    .mark-incomplete {
        display: inline-block;
    }
    .icon-check {
      display: inline-block
    }

    .content {
      opacity: 0.8;
    }
  }
  .start-btn {
    font-size: 110%;
    float: left;
    svg.icon {
      margin-bottom: 2px;
    }
  }

  .mark-complete {
      display: inline-block;
  }
  .mark-incomplete {
      display: none;
  }
}

section.chapter {
  .step {
    border: 5px solid #1bb0ee;
    background-color: #f5f5f5;

    h2 {
      margin: 15px 0 12px 0;
    }
    .icon-check {
      display: none;
      float: right;
      font-size: 100px;
    }

    .button {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    .figure-wrap:not(.pull-right):not(.pull-left), [class*="code-snippet-"] {
      margin-left: 0;
      margin-right: 0;
      margin-top: 1.7em;
    }

    &.tutorial-step-complete {
      border: 5px solid #dde8ec;

      .mark-complete {
          display: none;
      }
      .mark-incomplete {
          display: inline-block;
      }

      .icon-check {
        display: inline-block
      }

      .content {
        opacity: 0.8;
      }
    }

    .notice {
      margin-left: 0;
      margin-right: 0;
    }

    p + ul, p + ol {
      margin-top: 0;
    }

    p > img {
        border: 1px dotted #ccc;
        border-radius: 10px;
        height: 2.5em;
    }
  }

  .inter-step {
    height: 80px;
    width: 0;
    margin: 0 auto;
    border: 2px dashed #1bb0ee;
  }

  .complete-btn {
    text-align: right;
    font-size: 110%;
  }

  .mark-complete {
    display: inline-block;
    border: 3px solid #1bb0ee;
    background-color: #1bb0ee;
    &:hover {
      background-color: #1bb0ee;
    }
  }
  .mark-incomplete {
    display: none;
    background-color: #f5f5f5;
    border: 3px solid #dde8ec;
    &:hover {
      background-color: #f5f5f5;
    }
    color: #1bb0ee;
  }

  .progress {
    padding: 1.5em;
    background-color: #dde8ec;
    border-radius: 10px;
    font-size: 1.3em;
    line-height: 1.5em;
    margin-top: -4px;
    position: sticky;
    top: 0;
    h3 {
      margin: 0.2em 0 0.2em 0;
      font-weight: normal;
    }
    ul {
      list-style-type: none;
      .progress-number {
        display: inline;
        margin-right: 0px;
        margin-left: -16px;
      }
      .fa-check {
        color: #1bb0ee;
        display: none;
      }
      .fa-check, .fa-ellipsis-h {
        font-size: 10pt;
        vertical-align: 0em;
        margin-right: 2px;
        margin-left: -20px;
      }
    }
    ul>li:before {
      // Removes custom bullet points from _base.scss
      content: none;
    }
    li {
      font-size: 0.8em;
      padding-left: 0;
      li {
        .fa-check {
          margin-right: 8px;
          margin-left: -16px;
        }
        .progress-number {
          display: inline;
          margin-right: 12px;
          margin-left: -15px;
        }
      }
      &.tutorial-complete, &.tutorial-chapter-complete, &.tutorial-step-complete {
        >.fa-check {
         display: inline;
        }
        &>.progress-number {
         display: none;
        }
        .fa-ellipsis-h {
          display: none;
        }
      }
      &.this-chapter {
        font-weight: bold;
        li {
          font-weight: normal;
        }
      }
      a {
        color: inherit;
        text-decoration: inherit;
        &:hover {
          color: #1bb0ee;
        }
      }
    }
  }
}

.tutorial-container {
  margin-top: 160px;

  .tutorial-section-tagline {
    font-weight: normal;
  }

  .tutorial-item {
      .content {
          .title {
              font-size: 14px;
          }
          .summary {
            font-size: 14px;
          }
      }
  }

  i.arrow {
    font-size: 1.5em;
    svg {
      stroke: white;
      &.icon {
        width: 1em;
        height: 1em;
        vertical-align: middle;
      }
    }
  }

}

.get-started-item {
    text-decoration: none;
    display: flex;
    background-color: white;
    border-radius: 7px;
    margin: 12px;
    cursor: pointer;
    overflow: hidden;

    box-shadow: 0px 0px 76px 0px rgba(27,27,27,0.1);

    &:hover {
        box-shadow: 0px 0px 64px 0px rgba(27,27,27,0.25);
    }

    @media(max-width: 960px) {
        flex-direction: column;
        
    }
    .thumb {
        background-size: cover; 
        background-repeat: no-repeat; 
        min-height: 300px; 
        min-width: 600px;
        flex-shrink: 0;

        // @media(max-width: 960px) {
        //     width: auto;

        // }

        @media(max-width: 960px) {
            padding-bottom: 60%;
            height: auto;
            width: 100%;
        }
    }

    .content {
        padding: 12px;
        // width: 272px;
        margin: 8px 4px;

        .title {
            font-weight: bold;
            font-size: 22px;
            color: #222;
        }

        .summary {
            color: #222;
        }

        @media(max-width: 960px) {
            width: auto;
        }
    }

}

.tutorial-essentials-container, .read-more-container {

  &.narrow {
    .tutorial-essential-body {
      margin: 0 10px;
      padding: 10px;
      box-shadow: 0px 0px 30px 0px rgba(27,27,27,0.06);
      &.fastest-tutorial {
        box-shadow: 0px 0px 30px 0px #1bb0ee66;
      }
      .button-holder {
        padding-top: 0;
        margin-right: -15px;
        .button i svg {
          fill: white;
          stroke: white
        }
      }

      .content {
        padding: 15px;
        .title {
          padding-bottom: 0;
        }
      }
    }

    .banner {
      margin: 0 -5px;
      height: 40px;

      &:before, &:after {
        border-width: 0 0 15px 15px;
        width: inherit;
        height: inherit;
      }
      &:before {
        top: 27px;
        left: -13px;
      }
      &:after {
        bottom: -39px;
      }
    }

    .summary {
      display: none;
    }

    .thumbnail {
      height: 150px;
    }

    padding-bottom: 40px;
  }

  padding-bottom: 0;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 50px auto;

  .banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 1/2;
    margin: 0;
    bottom: -20px;
    &:before {
      transform: rotate(270deg);
//      bottom: -25px;
      top: 50px;
    }
  }

  .fastest-tutorial {
    grid-column: 1/2;
    box-shadow: 0px 0px 76px 0px #1bb0eeaa;
  }

  .tutorial-essential-body, .read-more-body {
    margin: 0 25px;
    grid-row: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .button-holder {
      padding-top: inherit;
      display: flex;
      justify-content: flex-end;
    }
    overflow: hidden;
    >.thumbnail {
      padding-top: 50%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      border-bottom: 1px solid #ccc;
    }
    text-decoration: none;
    color: inherit;

    position: relative;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      padding: 2em;
      .title {
          padding-bottom: 0.5em;
      }

      div.summary {

      }

      .human-date {
          color: #999;
      }
    }
  }

  @media(max-width: 860px) {
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr 1fr 1fr;
    grid-row-gap: 50px;
    margin-bottom: 100px;

    .banner {
      grid-column: 1/2;
      grid-row: 1/2;
      bottom: -70px;
    }

    .fastest-tutorial {
      grid-column: 1/2;
      grid-row: 1/2;
    }

    .tutorial-essential-body {
      grid-column: 1/2;
      grid-row: auto;
    }
  }
}


.read-more-container {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: unset;
  grid-auto-rows: auto;
  margin: -20px -25px 0px -25px;

  @media(max-width: 600px) {
    margin-top: 10px;
  }

  .read-more-body {
    grid-row: unset;
    margin: 25px;
    box-shadow: 0px 0px 36px 0px rgba(27,27,27,0.05);

    .content {
      padding-bottom: 0;
    }

    .summary-and-thumbnail {
      display: none;
    }
    a>.thumbnail {
      display: unset;
    }

    @media(max-width: 600px) {
      margin: 0;

      grid-column: 1/3;
      .button-holder {
        justify-content: center;
      }
    }

    @media(min-width: 600px) {
      &.wide {
        grid-column: 1/3;
        flex-direction: row;

        a>.thumbnail {
          display: none;
        }

        .summary-and-thumbnail {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-grow: 1;
          position: relative;

          .thumbnail {
            width: 50%;
            align-self: center;
            margin-right: 25px;
            border: 1px solid #ccc;
            &.right {
              order: 100;
              margin-right: inherit;
              margin-left: 25px;
              margin-bottom: 15px;
            }
          }
          .summary {
            width: 50%;
          }
        }

        .button-holder {
          padding: 0;
        }
      }
    }

  }

  i.arrow svg {
    stroke: white;
  }

}


.tutorial-features-container, .tutorial-builds-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  .tutorial-feature {
    width: 33.33%;
    @media(max-width: 900px) {
        width: 50%;
    }
    @media(max-width: 600px) {
        width: 100%;
    }
    padding: 2%;
    flex-grow: 0;
    flex-shrink: 0;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      a {
        align-self: flex-end;
      }
      .title {
        background: #fafafa;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 0px 76px 0px rgba(27,27,27,0.3);
      }
    }

    >.card {
      height: 100%;
      border-radius: 0;
      /*> div { border-radius: 10px; }*/
      .content {
        height: 100%;
      }
    }

    .button {
      box-shadow: 0px 0px 76px 0px rgba(27,27,27,0.3);
    }
  }

  .tutorial-build {
      flex-basis: 50%;
      flex-grow: 1;
      margin: 2%;
      >.card {
        height: 100%;
      }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}


.tutorial-modal {
  display: none;
  &.active {
    display: block;
  }

  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);

  .tutorial-modal-content {
    margin: 15vh auto;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    line-height: normal;

    &:hover, &:focus {
      color: #222;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.tutorial-tabs {
    a {
        text-decoration: none;
        color: white;
        padding: 0px 12px;
        margin-right: 28px;
        margin-left: -12px;
        display: inline-block;
        font-size: 22px;

    }

    a:hover{
        background-color: rgba(42, 177, 235, .4);
        border-radius: 10px;
        // text-decoration: underline solid #08A9E4;
    }

}

a.card.picture-link {
    display: block;
    padding: 0;
    overflow: hidden;
    text-decoration: none;

    color: inherit;
    &:hover {
        color: #444;
        h4 { color: #111; }
    }
    img {
        width:100%;
        border-bottom: 1px solid #eee;
    }
    .description {
        padding: 0 1.5em;
    }
    &.with-summary .description {
        padding-bottom: 1.5em;
    }
    @media(min-width: 900px) {
        max-width: 70%;
    }
}

//new Examples page

.example-container {
    margin-top: 160px;

    h2 {
        clear: both;
        @media(max-width: 860px) {
            padding-top: 2em;
        }
    }

    h3 {
        font-weight: normal;
    }
}

.example-item {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 7px;
    margin: 12px;
    cursor: pointer;
    z-index: 1;
    box-shadow: 0px 0px 76px 0px rgba(27,27,27,0.1);

    &:hover {
        box-shadow: 0px 0px 64px 0px rgba(27,27,27,0.25);
    }



    @media(max-width: 960px) {

    }

    // @media(max-width: 600px) {
    //     .thumb {
    //         width: 100% !important;
    //         height: auto !important;
    //     }
    // }

    .content {
        padding: 12px;
        // width: 272px;
        margin: 8px 4px;

        .title {
            font-weight: bold;
            font-size: 18px;
            color: #222;
            padding-bottom: 0.4em;
        }

        .summary {
            color: #222;
        }
    }
}


.example-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    // flex-flow: row wrap;
    // justify-content: space-around;

    a {
        text-decoration: none;
    }

    @media(max-width: 1160px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
    }
    @media(max-width: 860px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }
    @media(max-width: 600px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        // grid-row-gap: 50px; 
    }

}

.seemore {
    svg {transform: rotate(90deg);}
}

.seeless {
    svg {transform: rotate(-90deg);}
}

.seebutton {
    background-color: #f7fdff;
    display: block;
    border: none;
    color: #3D3D3D;
    svg {stroke:#515151; font-size: 1.1em; vertical-align: sub; margin-left: 15px;}
    font-size: 20px;
    margin-top: 1em;
    margin-bottom: 1em;
    float: right;
    grid-column: 4;
    text-align: right;
}

.buttons {
    margin-top: 10px;
    margin-bottom: 10px;
}

.seebutton:hover {
    cursor: pointer;
    color: #2AB1EB;
}
.seebutton:hover > svg {
    stroke: #2AB1EB;
}

.hidden {
    display: none;
}

// Advent-calendar-specific things:
// Homepage
.advent-calendar {

    padding: 40px 0;

    .row {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 30px;
    }

    a {
        flex-grow: 0;
        width: 250px;
    }

    a {
        text-decoration: none;
        //border: 5px solid;
        min-height: 250px;
        position: relative;
        padding: 20px;
        font-size: 23px;

        color: white;
        background-color: #B71C1C; // #D32F2F
        border-color: #B71C1C;
        &.open-door {
            font-weight: 700;
            //background-color: #B71C1C;
            &:hover { background-color: #821010; }

            .title, .day {
                position: relative;
                z-index: 1;
            }

            .bg {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0.1;
                background-size: cover;
                background-position: left;
                z-index: 0;
            }

        }

        .day {
            font-weight: bold;
            font-size: 48px;
            line-height: 1;
            padding-bottom: 10px;
        }

        &.closed-door {
            overflow:hidden;

            .day::after {
                content: "?";
                position: absolute;
                bottom: -66px;
                right: -13px;
                font-weight: bold;
                font-size: 300px;
                color: #d04141;
            }
        }
    }

    @media(min-width: 901px) {
        .row:nth-child(odd) a:nth-child(even), .row:nth-child(even) a:nth-child(odd) {
            background-color: #1B5E20; // #388E3C
            //border-color: #1B5E20;
            &.open-door {
                //background-color: #1B5E20;
                &:hover {
                    background-color: #103a13;
                }
            }
            .day::after {
                color: #388E3C;
            }
        }
    }

    @media(max-width: 1180px) {
        a {
            width: 200px;
            .day {
                font-size: 28px;
            }
            font-size: 20px;
            min-height: 150px;
            &.closed-door .day::after {
                bottom: -50px;
                right: -13px;
                font-size: 200px;
            }
        }
    }

    @media(max-width: 900px) {
        .row {
            flex-wrap: wrap;
            margin-bottom: 0;
        }
        a {
            width: calc(50% - 20px);
            margin-left: 10px;
            margin-right: 10px; 
            flex-shrink: 0;
            margin-bottom: 20px;
        }
        a:nth-child(1), a:nth-child(4) {
            background-color: #1B5E20; // #388E3C
            //border-color: #1B5E20;
            &.open-door {
                //background-color: #1B5E20;
                &:hover {
                    background-color: #103a13;
                }
            }
            .day::after {
                color: #388E3C;
            }
        }
    }

    @media(max-width: 600px) {
        a {
            .day {
                font-size: 22px;
            }
            font-size: 16px;
        }
    }

}

article {
  h3.app-url {
    padding-bottom: 0.6em;
  }
}

.christmas-callout {
    margin: 1em auto 2em;
    @media(min-width: 600px) {
        width: 75%;
    }
    border: 1px solid #ddbfc0;
    background-color: #ecf1f4;
    background-image: url("/advent/img/white-presents.jpeg");
    background-position: bottom;
    background-size: cover;
    border-radius: 10px;

    @media (min-width: 450px) {
        padding: 1em 18px;
    }

    @media (min-width: 800px) {
        form { display: flex; }
    }

    div.block {
        padding: 1em 18px;
        background-color: #f7fdff9f;
    }

    p {
      /*color: white;
      span {
        background-color: #1d0c00cf;
        padding: 2px 8px;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
      }*/
    }
    p.prompt {
        margin-bottom: 0;
    }

    /*span {
        background-color: #f7fdffcf;
        padding: 2px 8px;
    }*/

    h1,h2,h3,h4 {
        color: #222;
        font-weight: 700;
        /*span {
          background-color: #f7fdffcf;
          padding: 2px 8px;
        }*/
    }

    @media (min-width:700px) {
        form {
            /*display: flex;*/
            input {
                flex-grow: 1;
                margin-right: 20px;
            }
            label {
                flex: 0;
                min-width: 170px;
            }
        }
    }

    a {
      &:hover { color: #9e1b1d; }
      text-decoration: none;
      font-weight: 400;
    }

    .sharing-links {
        flex-wrap: wrap;
    
        a {
            color: #222;
            &:hover { color: #9e1b1d; }
            background-color: #f7fdffcf;
            padding: 10px 20px;

            @media(max-width: 900px) {
                i {
                    font-size: 2em;
                }
            }
            @media(max-width: 700px) {
                padding: 5px;
            }
            @media(max-width: 380px) {
                padding: 10px 20px;
            }
        }

    }

    a.button {
        color: #fff;
        padding: 0.6em 3.5em 0.6em 1.5em;
        text-decoration: none;
        border-radius: 100px;
        display: inline-block;
        margin: 0.5em 0.2em;
        position: relative;
        color: white;
        background-color: #46372d;
        font-weight: normal;
        font-size: 18px;
        padding: 16px 26px;
        padding-right: 62px;
        i {
            /* I've no idea how this is working, needs fixing! */
            right: 16px;
            top: 16px;
            line-height: 1.08;
            svg { stroke: white; }
        }
        &:hover {
            color: white;
            background-color: #2b211b;
        }
        cursor: pointer;
    }

    .all-apps {
        text-align: right;
    }
}

.hanukkah-callout {
  @extend .christmas-callout;
  background-image: url("/hanukkah/img/pexels-cottonbro-4033330.jpg");
}

.hanukkah-calendar {
 @extend .advent-calendar;

     a {
        background-color: #f7d024; // #D32F2F
        border-color: #f7d024;
        &.open-door {
            &:hover { background-color: #ddb608; }
        }
     }

    @media(min-width: 901px) {
        .row:nth-child(odd) a:nth-child(even), .row:nth-child(even) a:nth-child(odd) {
            background-color: #1bb0ee; // #388E3C
            //border-color: #1B5E20;
            &.open-door {
                //background-color: #1B5E20;
                &:hover {
                    background-color: #0c78a6;
                }
            }
            .day::after {
                color: #0066ff;
            }
        }
    }
}


.figurecode {
  .code-snippet-server, .code-snippet-client, .code-snippet {
    margin-top: 0;
  }
  &.code-hidden {
    .code-snippet-server, .code-snippet-client, .code-snippet, .figure-btn {
      display: none;
    }
  }
  &.figure-hidden {
    .figure-wrap, .code-btn {
      display: none;
    }
  }
  figure {
    border-top-left-radius: 0px;
  }

  .buttons {
    @media(min-width: 900px) {
      margin-left: -$blog-text-gutter;
    }
    i {
      svg { stroke: #222; }
    }
    .button {
      background-color: #dde8ec;
      color: #222;
      margin-bottom: -5px;
      margin-left: 0;
      border-radius: 10px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      cursor: pointer;
    }
  }
}

.callout-plotting {
  background-color: #dde8ec;
  border-color: #222;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom-left;

  .button {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 45px;
    width: 100px;
    border-radius: 5px;
    &.active {
      border: 3px solid #1bb0ee;
      filter: opacity(75%);
    }
  }

  h3 {
    color: #1bb0ee;
    margin-bottom: 20px;
    display: block;
  }

  a {
    text-decoration: none;
  }
}


.callout-seo {
  background-color: #dde8ec;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  h3 {
    color: #1bb0ee;
    margin-bottom: 20px;
    display: block;
  }

  a {
    text-decoration: none;
  }

  .cta-container {
    text-align: left;
  }
}

.article-table {
    margin:0 auto;
    padding:10px;
    a {
      text-decoration: none;
    }
    table {
      width:100%;
      border-collapse: collapse;
      border-spacing: 0;
      table-layout: fixed;
    }
    td, th {
      border-right:1px solid #CCC;
      empty-cells: show;
      padding:10px;
      border:1px solid #CCC;
    }
    th {
      text-align:center;
      font-weight:normal;
      padding:0;
      border-bottom:1px solid #CCC;
    }
    .compare-row {
      background:#F5F5F5;
    }
    .compare-heading {
        padding: 12px !important;
        span {
          font-size:18px;
          font-weight:bold !important;
          border-bottom:0 !important;
        }
    }
    i.fa-check-circle {
      color: #2AB1EB;
    }
    .tl {
      color:#FFF;
      padding:10px;
      font-size:13px;
      border-right:1px solid #CCC;
      border-left: none;
      border-top: none;
    }
}

.competitor-pages-features-container {
    // margin-top: 22px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .competitor-pages-feature {
        display: inline-flex;
        align-items: center;
        margin-bottom: 40px;
        
        p {
            margin: 0;
        }
        i {
            margin-right: 20px;
            width: 2.8em;
            height: 2.8em;
            flex-shrink: 0;
        }
        .description {
            font-size: 22px;
            line-height: 30px;
        }
    }

}

.blog-table-wrapper {
    overflow-x: auto;

    @media(min-width:998px) {
        margin-left: -55px;
        margin-right: -55px;
        display: flex;
        justify-content: center;

    }

    table.blog-table {
        border: 5px solid #E4F7FF;
        overflow-x: auto;
        
    
        td {
            min-width: 100px;
            padding: 5px;
        }
    
        thead {
            font-weight: 600;
            text-align: center
    
        }
    
        tbody {
            tr:nth-child(odd) {
                background-color: #E4F7FF;
            }
    
            td:not(:last-child) {
                border-right: 5px solid #E4F7FF;
            }
    
            td {
                text-align: center;
            }
    
            tr td:nth-child(1) {
                font-weight: 600;
                text-align: left
            }
        }
    
    }
}

.community {
    display: flex; 
    flex-wrap: wrap;
    gap: 32px;
    justify-content: space-evenly;
    margin-top: 40px;

    .community-card {
        background: linear-gradient(rgba(27, 176, 238, 0.8), rgba(27, 176, 238, 0.8)), url(/img/home/features-background-1.png);
        box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);

        &:hover, &:focus {
            box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.35);
            -webkit-box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.35);
            -moz-box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.35);
        }
        &:active {
            box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
        }
    
        >a {
            color: white;

            >h3 {
                color: white;
            }
        }
    }

    .share-card {
        box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
        
        &:hover, &:focus {
            box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.25);
        }
        &:active {
            box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
        }

        >a {
            color: #555555;

            >h3 {
                color: #222222;
            }
        }
    }

    .share-card, .community-card {
        border-radius: 10px;
        padding: 20px;
        text-align: center;
        width: 350px;

        >a {
            display: grid;
            justify-items: center;
            grid-template-rows: 1fr 2.5fr;
            height: 100%;
            text-decoration: none;
            

            >p {
                margin-bottom: 0px;
            }

            &.logos {
                display: flex;
                justify-content: center;
                align-items: center;

                >img {
                    padding: 20px;
                }
            }

            >h3 {
                font-weight: 700;
                align-self: center;
            }

        }
    }
}

.tableau {

    .emphasis {
        color: #1bb0ee; 
        font-weight: 700;
    }

    &.wrapper-medium {
        .options-light {
            padding-top: 0px;
        }
        h2 {
            text-align: center;
            margin-bottom: 0.83em;
        }
    }

    .tdx-submit-button[disabled] + a{
        cursor: not-allowed;
        background-color: rgba(27, 176, 238, .12);
        color: rgba(255, 255, 255, .38);

        svg {
            stroke: rgba(255, 255, 255, .38) !important;
        }
    }


    .sign-up-form {
        max-width: 700px;
        // display: flex;
        // flex-wrap: wrap;
        // column-gap: 20px;

        a.button {
            margin-left: 0;
        }

        label {
            // display: block;
            // text-align: right;
            font-weight: 400;
        }

        input {
            margin-top: 20px;
            background-color: #555555;
            border: none;
            color: #ffffff;
    
            &:focus {
                outline: none;
                color: #ffffff;
                // border-color: $primary;
            }

            &::placeholder {
                color: #999999;
            }
        }

    }

    .anvil-x-transforms {
        display: flex;
        column-gap: 20px;
        align-items: center;

        @media (max-width: 1023px) {
            flex-wrap: wrap;
            flex-direction: column-reverse;

        }

        .anvil-x-animation {
            @media (max-width: 900px) {
                width: 100%;
                height: auto;
            }

        }

        .anvil-x-steps {
            padding-left: 20px;
    
            >li {
                color: #1bb0ee;
                font-weight: 800;
                font-size: 20px;
                margin-top: 16px;
            }
    
            >p {
                padding-left: 22px;
            }
        }
    }

    .get-started-container {
        display: flex; 
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 20px;

        a {
            max-width: 370px;
        }
    }


}

.banner-ad {
    padding: 20px;
    margin: 1em 0;

    // There's no point in showing an ad on mobile who's CTA takes them to the build page
    @media (max-width: 700px) {
        display: none;
    }
    .banner-ad {
        margin: 1em 0;
    }

    >div {
        width: 100%;
        &>*:last-child {
            margin-bottom: 0;
        }
    }

    p {
        color: white;
        padding: 0.5em 0.2em;
        margin: 0px;
        margin: 0 1.5em;
        font-size: 1.8em;
        line-height: 1.4em;
    }

    .underscore {
        background-size: 1px 5px;
        background-position: 0 1.11em;
    }

    .button {
        max-width: max-content;
        justify-self: center;
    }

    .button.anvil {
      }

    background-size: 85px 85px, cover;
    background-position: left 34px top 50%, left;
    background-repeat: no-repeat no-repeat;

    background-color: rgba(25, 49, 81, 0.5);
    border: 1px solid;
    border-color: #193151;
    background-image: url('/img/svg/logo-square-white-border.svg'), url('/img/sticky-footer-background.png');
    padding-left: 100px;
    display: grid;
    grid-template-columns: 4fr 2fr;
    align-items: center;
    border-radius: 10px;
}

.contact-card {
    border-radius: 5px; 
    //box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25); 
    padding: 24px 38px; 
    margin-bottom: 42px; 
    background-color: #EDF1F4;

    a:not(.button) {
        font-weight: 600;
    }

    ul, ol {
        margin-bottom: 0px;
    }

    //cta-continer class comes from the cta shortcode
    .cta-container {
        text-align: left;
    }

    .btn-container {
        display: flex;
        column-gap: 1em;
        margin-top: 1.5em;

        @media(max-width: 720px) {
            flex-direction: column;
        }

    }
}
