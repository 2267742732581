[class*="code-snippet-"] {

    padding: 36px 0;
    border-radius: 10px;
    margin: 2em -38px;
    position: relative;
    background-color: #29282D;
    border-radius: 10px;
    overflow-x: hidden;
    z-index: 2; // Make code float over secondary nav as it scrolls past.
    transition: margin-right 0.2s;
    clear: both;
    line-height: 1.4;

    &::after {
        content: '→';
        color: white;
        position: absolute;
        top:0;
        bottom: 0;
        right: 0;
        width: 60px;
        text-align: right;
        line-height: 40px;
        padding-right: 10px;
    };

    @media(min-width: 1280px) {
        &:hover {
            // -340px is the amount it can expand before it gets cut off
            margin-right: -340px !important;
            overflow-x: overlay;
    
            &::after {
                content: none;
            }
        }
    }

    @media(max-width: 1280px) {
        overflow-x: overlay !important;

        &::after {
            content: none;
        }
    }



    &.code-snippet-client {
        background-color: #29282D;
        &::after {
            background: linear-gradient(to right, rgba(41, 40, 45, 0), rgb(41, 40, 45) 40px);
        }

        .highlight pre:before {
            background-color: $primary;
        }
    }
    &.code-snippet-server {
        background-color: #29282D;//#37353B;
        &::after {
            background: linear-gradient(to right, rgba(55, 53, 59, 0), rgb(55, 53, 59) 40px);
        }

        .highlight pre:before {
            background-color: $secondary;
        }
    }

    .highlight  {
        pre { 

            &:before {
                content: '';
                width: 5px;
                background-color: #39353a;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 38px;
                border-radius: 50px;
            }

            margin: 0;
            padding: 0 32px 0 69px;
            position: relative;

            code {
                color: #f8f8f2;
                

                .hll { background-color: #272822; }
                .c { color: #75715e } /* Comment */
                .err { color: #960050; background-color: #1e0010 } /* Error */
                .k { color: #66d9ef } /* Keyword */
                .l { color: #ae81ff } /* Literal */
                .n { color: #f8f8f2 } /* Name */
                .o { color: #f92672 } /* Operator */
                .p { color: #f8f8f2 } /* Punctuation */
                .cm { color: #75715e } /* Comment.Multiline */
                .cp { color: #75715e } /* Comment.Preproc */
                .c1 { color: #75715e } /* Comment.Single */
                .cs { color: #75715e } /* Comment.Special */
                .ge { font-style: italic } /* Generic.Emph */
                .gs { font-weight: bold } /* Generic.Strong */
                .kc { color: #66d9ef } /* Keyword.Constant */
                .kd { color: #66d9ef } /* Keyword.Declaration */
                .kn { color: #f92672 } /* Keyword.Namespace */
                .kp { color: #66d9ef } /* Keyword.Pseudo */
                .kr { color: #66d9ef } /* Keyword.Reserved */
                .kt { color: #66d9ef } /* Keyword.Type */
                .ld { color: #e6db74 } /* Literal.Date */
                .m { color: #ae81ff } /* Literal.Number */
                .s { color: #e6db74 } /* Literal.String */
                .na { color: #a6e22e } /* Name.Attribute */
                .nb { color: #f8f8f2 } /* Name.Builtin */
                .nc { color: #a6e22e } /* Name.Class */
                .no { color: #66d9ef } /* Name.Constant */
                .nd { color: #a6e22e } /* Name.Decorator */
                .ni { color: #f8f8f2 } /* Name.Entity */
                .ne { color: #a6e22e } /* Name.Exception */
                .nf, .fm { color: #a6e22e } /* Name.Function */
                .nl { color: #f8f8f2 } /* Name.Label */
                .nn { color: #f8f8f2 } /* Name.Namespace */
                .nx { color: #a6e22e } /* Name.Other */
                .py { color: #f8f8f2 } /* Name.Property */
                .nt { color: #f92672 } /* Name.Tag */
                .nv { color: #f8f8f2 } /* Name.Variable */
                .ow { color: #f92672 } /* Operator.Word */
                .w { color: #f8f8f2 } /* Text.Whitespace */
                .mf { color: #ae81ff } /* Literal.Number.Float */
                .mh { color: #ae81ff } /* Literal.Number.Hex */
                .mi { color: #ae81ff } /* Literal.Number.Integer */
                .mo { color: #ae81ff } /* Literal.Number.Oct */
                .sb { color: #e6db74 } /* Literal.String.Backtick */
                .sc { color: #e6db74 } /* Literal.String.Char */
                .sd { color: #e6db74 } /* Literal.String.Doc */
                .s2 { color: #e6db74 } /* Literal.String.Double */
                .se { color: #ae81ff } /* Literal.String.Escape */
                .sh { color: #e6db74 } /* Literal.String.Heredoc */
                .si { color: #e6db74 } /* Literal.String.Interpol */
                .sx { color: #e6db74 } /* Literal.String.Other */
                .sr { color: #e6db74 } /* Literal.String.Regex */
                .s1 { color: #e6db74 } /* Literal.String.Single */
                .ss { color: #e6db74 } /* Literal.String.Symbol */
                .bp { color: #f8f8f2 } /* Name.Builtin.Pseudo */
                .vc { color: #f8f8f2 } /* Name.Variable.Class */
                .vg { color: #f8f8f2 } /* Name.Variable.Global */
                .vi { color: #f8f8f2 } /* Name.Variable.Instance */
                .il { color: #ae81ff } /* Literal.Number.Integer.Long */

                .gh { } /* Generic Heading & Diff Header */
                .gu { color: #75715e; } /* Generic.Subheading & Diff Unified/Comment? */
                .gd { color: #f92672; } /* Generic.Deleted & Diff Deleted */
                .gi { color: #a6e22e; } /* Generic.Inserted & Diff Inserted */
            }

        }
    }
}